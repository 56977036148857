
export const Categories = [
  {
    id: 1,
    categoryId: 1,
    category: 'Confectionery & Gum based product',
    title: "Almond",
    Image: '/Image/Confectionery.jpg',
    cName: 'nav-text'
  }, {
    id: 2,
    categoryId: 1,
    category: 'Confectionery & Gum based product',
    title: "Badam milk",
    Image: '/Image/Confectionery.jpg',
    cName: 'nav-text'
  }, {
    id: 3,
    categoryId: 1,
    category: 'Confectionery & Gum based product',
    title: "Hazel nuts",
    Image: '/Image/Confectionery.jpg',
    cName: 'nav-text'
  }, {
    id: 4,
    categoryId: 1,
    category: 'Confectionery & Gum based product',
    title: "Honey & Lemon",
    Image: '/Image/Confectionery.jpg',
    cName: 'nav-text'
  }, {
    id: 5,
    categoryId: 1,
    category: 'Confectionery & Gum based product',
    title: "coconut",
    Image: '/Image/Confectionery.jpg',
    cName: 'nav-text'
  }, {
    id: 6,
    categoryId: 2,
    category: 'Beverages',
    title: "Apple",
    Image: '/Image/Beverages.png',
    cName: 'nav-text'
  }, {
    id: 7,
    categoryId: 2,
    category: 'Beverages',
    title: "Orange",
    Image: '/Image/Beverages.png',
    cName: 'nav-text'
  }, {
    id: 8,
    categoryId: 2,
    category: 'Beverages',
    title: "Lemon",
    Image: '/Image/Beverages.png',
    cName: 'nav-text'
  }, {
    id: 9,
    categoryId: 2,
    category: 'Beverages',
    title: "Mango",
    Image: '/Image/Beverages.png',
    cName: 'nav-text'
  }, {
    id: 10,
    categoryId: 2,
    category: 'Beverages',
    title: "Pineapple",
    Image: '/Image/Beverages.png',
    cName: 'nav-text'
  },
  {
    id: 11,
    categoryId: 3,
    category: 'Fruit juices other Fruit based Beverages',
    title: "Banana",
    Image: '/Image/Fruit juices.png',
    cName: 'nav-text'
  }, {
    id: 12,
    categoryId: 3,
    category: 'Fruit juices other Fruit based Beverages',
    title: "Black Current",
    Image: '/Image/Fruit juices.png',
    cName: 'nav-text'
  }, {
    id: 13,
    categoryId: 3,
    category: 'Fruit juices other Fruit based Beverages',
    title: "Orange",
    Image: '/Image/Fruit juices.png',
    cName: 'nav-text'
  }, {
    id: 14,
    categoryId: 3,
    category: 'Fruit juices other Fruit based Beverages',
    title: "Lime",
    Image: '/Image/Fruit juices.png',
    cName: 'nav-text'
  }, {
    id: 15,
    categoryId: 3,
    category: 'Fruit juices other Fruit based Beverages',
    title: "Mixed Fruits",
    Image: '/Image/Fruit juices.png',
    cName: 'nav-text'
  },
  {
    id: 16,
    categoryId: 4,
    category: 'Biscuits & Bakery',
    title: "Butter",
    Image: '/Image/Biscuits & Bakery.png',
    cName: 'nav-text'
  },{
    id: 17,
    categoryId: 4,
    category: 'Biscuits & Bakery',
    title: "Butterscitch",
    Image: '/Image/Biscuits & Bakery.png',
    cName: 'nav-text'
  },{
    id: 18,
    categoryId: 4,
    category: 'Biscuits & Bakery',
    title: "chocolate",
    Image: '/Image/Biscuits & Bakery.png',
    cName: 'nav-text'
  },{
    id: 19,
    categoryId: 4,
    category: 'Biscuits & Bakery',
    title: "cinnamom",
    Image: '/Image/Biscuits & Bakery.png',
    cName: 'nav-text'
  },{
    id: 20,
    categoryId: 4,
    category: 'Biscuits & Bakery',
    title: "orange mint",
    Image: '/Image/Biscuits & Bakery.png',
    cName: 'nav-text'
  },
  {
    id: 21,
    categoryId: 5,
    category: 'Dairy Product Frozen dessertd',
    title: "Kesar pista",
    Image: '/Image/Dairy Product.png',
    cName: 'nav-text'
  },{
    id: 22,
    categoryId: 5,
    category: 'Dairy Product Frozen dessertd',
    title: "Kulfi",
    Image: '/Image/Dairy Product.png',
    cName: 'nav-text'
  },{
    id: 23,
    categoryId: 5,
    category: 'Dairy Product Frozen dessertd',
    title: "Rose milk",
    Image: '/Image/Dairy Product.png',
    cName: 'nav-text'
  },{
    id: 24,
    categoryId: 5,
    category: 'Dairy Product Frozen dessertd',
    title: "Blue berry",
    Image: '/Image/Dairy Product.png',
    cName: 'nav-text'
  },{
    id: 25,
    categoryId: 5,
    category: 'Dairy Product Frozen dessertd',
    title: "Cofee",
    Image: '/Image/Dairy Product.png',
    cName: 'nav-text'
  },
  {
    id: 26,
    categoryId: 6,
    category: 'Pharmaceutical',
    title: "Mango",
    Image: '/Image/Pharmaceutical.png',
    cName: 'nav-text'
  },{
    id: 27,
    categoryId: 6,
    category: 'Pharmaceutical',
    title: "Pineapple",
    Image: '/Image/Pharmaceutical.png',
    cName: 'nav-text'
  },{
    id: 28,
    categoryId: 6,
    category: 'Pharmaceutical',
    title: "Apple",
    Image: '/Image/Pharmaceutical.png',
    cName: 'nav-text'
  },{
    id: 29,
    categoryId: 6,
    category: 'Pharmaceutical',
    title: "Orange",
    Image: '/Image/Pharmaceutical.png',
    cName: 'nav-text'
  },{
    id: 30,
    categoryId: 6,
    category: 'Pharmaceutical',
    title: "Lemon",
    Image: '/Image/Pharmaceutical.png',
    cName: 'nav-text'
  },
  {
    id: 31,
    categoryId: 7,
    category: 'Spicy Nutty',
    title: "Dill",
    Image: '/Image/spicy nut.png',
    cName: 'nav-text'
  },{
    id: 32,
    categoryId: 7,
    category: 'Spicy Nutty',
    title: "Garlic",
    Image: '/Image/spicy nut.png',
    cName: 'nav-text'
  },{
    id: 33,
    categoryId: 7,
    category: 'Spicy Nutty',
    title: "Almond",
    Image: '/Image/spicy nut.png',
    cName: 'nav-text'
  },{
    id: 34,
    categoryId: 7,
    category: 'Spicy Nutty',
    title: "Badam",
    Image: '/Image/spicy nut.png',
    cName: 'nav-text'
  },{
    id: 35,
    categoryId: 7,
    category: 'Spicy Nutty',
    title: "Cashewnut",
    Image: '/Image/spicy nut.png',
    cName: 'nav-text'
  },
  {
    id: 36,
    categoryId: 8,
    category: 'Sweet',
    title: "Ma Mala",
    Image: '/Image/sweet.png',
    cName: 'nav-text'
  },{
    id: 37,
    categoryId: 8,
    category: 'Sweet',
    title: "Mawa Kulfi",
    Image: '/Image/sweet.png',
    cName: 'nav-text'
  },{
    id: 38,
    categoryId: 8,
    category: 'Sweet',
    title: "Kulfi",
    Image: '/Image/sweet.png',
    cName: 'nav-text'
  },{
    id: 39,
    categoryId: 8,
    category: 'Sweet',
    title: "Jamoon",
    Image: '/Image/sweet.png',
    cName: 'nav-text'
  },{
    id: 40,
    categoryId: 8,
    category: 'Sweet',
    title: "Gulkhand",
    Image: '/Image/sweet.png',
    cName: 'nav-text'
  },

];


export const filterCat = [
  {
    id: 1,
    category: 'Confectionery & Gum based product',
    Image: '/Image/ic1.svg',
  },
  {
    id: 2,
    category: 'Beverages',
    Image: '/Image/ic2.svg',
  },
  {
    id: 3,
    category: 'Fruit juices other Fruit based Beverages',
    Image: '/Image/ic3.svg',
  },
  {
    id: 4,
    category: 'Biscuits & Bakery',
    Image: '/Image/ic4.svg',
  },
  {
    id: 5,
    category: 'Dairy Product Frozen dessertd',
    Image: '/Image/ic7.svg',
  },  {
    id: 6,
    category: 'Pharmaceutical',
    Image: '/Image/ic6.svg',
  },  
  {
    id: 7,
    category: 'Spicy Nutty',
    Image: '/Image/ic6.svg',
  },  
  {
    id: 8,
    category: 'Sweet',
    Image: '/Image/ic6.svg',
  },  

]