import React from "react";
import Page from "./components/Pages/Page";
import './App.css'

function App() {
  return (
   <div> <Page/></div>
  );
}

export default App;
